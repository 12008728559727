import config from '../config';
export default {
  lang: `Ar`,
  begin: `إبدأ`,
  enterPGN: `أدخل رقم الملف`,
  enterPhone: `أدخل رقم هاتفك الخليوي`,
  samePhone: `هل هذا رقم هاتفك المسجل لدى المفوضية:`,
  enterDigits: `أدخل آخر ${config.digits} أرقام من اليمين إلى اليسار من بطاقتك الحمراء`,
  enterOTP: `ادخل الرقم السري الذي وصلك في الرسالة (${config.otp} أرقام)`,
  submit: `أدخل`,
  stillHaveCard: `هل لا زلت تملك البطاقة الحمراء؟`,
  submitAnotherOTP: `أرسل الرقم مرة أخرى`,
  stillHavePin: `هل لا زلت تملك الرقم السري؟`,
  yes: `نعم`,
  no: `كلا`,

  intro: `تطلق المفوضية عملية التحقق عن بعد من البطاقة الحمراء للعائلات النازحة أو المتضررة من الوضع في جنوب لبنان. يجب على العائلات  إتمام التحقق بنجاح لتلقي المساعدة عبر تعبئة المعلومات التالية`,

  error: `خطأً`,
  nvn: `لا حاجة للتدقيق`,
  cd: `الأرقام صحيحة`,
  wd: `الأرقام خاطئة`,
  ta: `محاولات خاطئة متكررة`,
  id: `الأرقام غير صحيحة`,
  cv: `لقد تم التحقق من البطاقة`,
  wp: `رقم الملف غير صحيح`,
  or: `الرمز السري`,
  ip: `رقم الهاتف غير صحيح`,
  wo: `الرقم السري غير صحيح`,
  woDesc: `لقد ادخلت رقم سري غير صحيح`,
  ipDesc: `لقد ادخلت رقماً غير صحيح`,
  orDesc: `سوف تصلك رسالة قصيرة تحتوي على رمز يتألف من ${config.otp} أرقام، أدخلهم في المربع و إضغط على <b>أدخل</b>`,
  wpDesc: `رقم الملف التابع للمفوضية غير صحيح`,
  cvDesc: `لقد تم التحقق من بطاقتك الحمراء`,
  idDesc: `أدخل ${config.digits} أرقام فقط`,
  nvnDesc: `لست بحاجة لإتمام عملية التدقيق`,
  cdDesc: `لقد ادخلت رمزاً صحيحاً`,
  wdDesc: `لقد ادخلت رقماً غير صحيح، تأكد أنك تدخل آخر ${config.digits} أرقام من ناحية اليمين `,
  taDesc: `لقد ادخلت ${config.attempts} محاولات خاطئة، ولم يعد بإمكانك المحاولة `,
  errDesc: `لقد حصل خطأً، يرجى المحاولة لاحقاً`,
  ok: `حسناً`,
  dir: `RTL`,
  verComp: `هذه نهاية العملية. تشكرك المفوضيّة على تعاونك وستتواصل معك من خلال رسالة نصيّة أو مكالمة هاتفيّة لإبلاغك بأي مستجدات`,
  tryAgain: `جرب مجدداً`,
  remainingAttemps: `المحاولات المتبقية: `,
  unhcrWillContact: `سوف تقوم المفوضية بالإتصال بك خلال بضعة أيام`,
  noCard: `شكراً لإكمال العملية والتأكيد أنك لا تملك البطاقة الحمراء. ستتواصل معك المفوضيّة من خلال رسالة نصيّة أو مكالمة هاتفيّة لإبلاغك بأي مستجدات.`,
  goBack: `العودة`,
};
