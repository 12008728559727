import React from 'react';
import Folder from 'react-ionicons/lib/IosFolderOpenOutline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCreditCard,
  faMobileAlt,
  faFolderOpen,
  faUnlockAlt,
} from '@fortawesome/free-regular-svg-icons';

import c from '../../utils/constants';
import { Root } from './styles';

const MyIcon = (props) => {
  let iconn = null;
  switch (props.icon) {
    case 'folder':
      iconn = faFolderOpen;
      break;
  }
  return (
    <Root>
      <FontAwesomeIcon
        fontWeight='1x'
        color={c.primary}
        size='7x'
        style={{
          WebkitTextStroke: '2px',
        }}
        icon={iconn}
      />
      {/* <Folder fontSize='8em' color={c.primary} /> */}
    </Root>
  );
};

export default MyIcon;
