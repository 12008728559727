import React, { useState, Fragment, useRef, useEffect } from 'react';
import axios from 'axios';
import LocalizedStrings from 'react-localization';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import InputMask from 'react-input-mask';
import 'bootstrap/dist/css/bootstrap.min.css';
import config from './config';
import c from './utils/constants';
import {
  Root,
  Logo,
  P,
  Input,
  SubmitButton,
  SendAnotherOTPButton,
  HasCardPin,
  YesNoButton,
  DIV,
  Center,
  Masked,
  GlobalStyle,
  Back,
} from './Styles';
import stringsToLocalize from './localisation';

import logo from './_images/unhcr-logo.png';
import Loading from './components/Loading';
import ErrorHandler from './components/ErrorHandler';
import Success from './components/Success';
import MyIcon from './components/MyIcon';

import { validatePhone, validateDigits, validatePGN } from './utils/funcs';

function App() {
  const baseURL = config.baseURL;
  const strings = new LocalizedStrings(stringsToLocalize);

  const [lang, setLang] = useState(config.lang);
  const [ver, setVer] = useStateWithCallbackLazy({});
  const [loading, setLoading] = useState(false);
  const [hasBack, setHasBack] = useState(false);
  const [hasCard, setHasCard] = useStateWithCallbackLazy(null);
  const [hasPin, setHasPin] = useStateWithCallbackLazy(null);
  const [stop, setStop] = useState(null);
  const [icon, setIcon] = useState('folder');
  const [showIcon, setShowIcon] = useState(false);
  const [maskedPhone, setMaskedPhone] = useStateWithCallbackLazy(null);
  const [showSendAnother, setShowSendAnother] = useState(false);
  const [validOTP, setValidOTP] = useState(false);
  const [errorHandler, setErrorHandler] = useStateWithCallbackLazy(null);

  const [error, setError] = useState(false);

  const [remainingAttemps, setRemainingAttemps] = useState(null);
  const [step, setStep] = useState(-1);
  const [isMounted, setIsMounted] = React.useState(false);

  const pgnRef = useRef(null);
  const digitsRef = useRef(null);
  const phoneRef = useRef(null);
  const otpRef = useRef(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(document.location.search);
    const lang = searchParams.get('lang');
    setLang(lang);
  });

  React.useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  strings.setLanguage(lang || config.lang);

  let remaining =
    strings.dir.toLowerCase() === 'ltr' ? (
      <P dir='LTR'>{strings.remainingAttemps + ' ' + remainingAttemps}</P>
    ) : (
      <P dir='RTL'>{strings.remainingAttemps + ' ' + remainingAttemps}</P>
    );

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      switch (e.target) {
        case pgnRef.current:
          handleSubmitPGN();
          break;
        case digitsRef.current:
          handleSubmitDigits();
          break;
        case phoneRef.current:
          handleSubmitPhone();
          break;
        case otpRef.current:
          handleSubmitOTP();
          break;
        default:
          break;
      }
    }
  };
  const handleSubmitPGN = async () => {
    setErrorHandler(null);
    const pgnx = validatePGN(pgnRef.current.value);
    if (pgnx === false) {
      setErrorHandler('WP');
    } else {
      setLoading(true);
      let result = {};
      try {
        result = await axios.post(baseURL + '/new', { pgn: pgnx });
      } catch (e) {
        setLoading(false);
        setErrorHandler('error');
        setError(true);
        return;
      }
      setLoading(false);
      if (result.data.msg) {
        setErrorHandler(result.data.msg);
        pgnRef.current.blur();
      }
      setVer(result.data, (ver) => {
        if (ver.VerGUID) {
          setMaskedPhone(ver.maskedPhone, () => {
            setStep(1);
            setRemainingAttemps(ver.Attempts);
          });
        }
      });
    }
  };
  const handleSubmitDigits = async () => {
    if (!validateDigits(digitsRef.current.value)) {
      setErrorHandler('ID');
      return;
    } else {
      setLoading(true);
      let result = {};
      try {
        result = await axios.post(baseURL + '/digits', {
          verGUID: ver.VerGUID,
          digits: digitsRef.current.value,
        });
      } catch (e) {
        setLoading(false);
        setErrorHandler('error');
        setError(true);
        return;
      }
      setLoading(false);
      if (result.data.msg) {
        setErrorHandler(result.data.msg);
        setRemainingAttemps(result.data.Attempts);
        if (result.data.cont) {
          setStep(2);
        }
      }
    }
  };
  const handleSubmitPhone = async (phone) => {
    let p = typeof phone === 'object' || !phone ? null : phone;
    if (p === null) {
      p = validatePhone(phoneRef.current.value);
      if (p === false) {
        setErrorHandler('IP');
        return;
      }
    }

    setLoading(true);
    let result = {};
    try {
      result = await axios.post(baseURL + '/otp', {
        verGUID: ver.VerGUID,
        phone: p,
        otpLen: config.otp,
      });
    } catch (e) {
      setLoading(false);
      setErrorHandler('error');
      setError(true);
      return;
    }
    setLoading(false);
    setErrorHandler('OR');
    setStep(5);
    setTimeout(() => {
      setShowSendAnother(true);
    }, 10000);
  };
  const handleSubmitSamePhone = async (samePhone) => {
    setLoading(true);
    let result = {};
    try {
      result = await axios.post(baseURL + '/samephone', {
        verGUID: ver.VerGUID,
        samephone: samePhone,
      });
      if (result.data.phone) {
        handleSubmitPhone(result.data.phone);
      }
    } catch (e) {
      setLoading(false);
      setErrorHandler('error');
      setError(true);
      return;
    }
    setLoading(false);
  };
  const handleSubmitOTP = async () => {
    setLoading(true);
    let result = {};
    try {
      result = await axios.post(baseURL + '/verifyotp', {
        verGUID: ver.VerGUID,
        otp: otpRef.current.value,
      });
    } catch (e) {
      setLoading(false);
      setErrorHandler('error');
      setError(true);
      return;
    }
    setLoading(false);
    if (result.data.Valid) {
      setValidOTP(true);
    } else {
      setErrorHandler('WO');
    }
  };
  const handleSubmitIntro = () => {
    setStep(0);
  };
  const submitHasCard = async (hasCard) => {
    let result = {};
    try {
      setLoading(true);
      result = await axios.post(baseURL + '/hascard', {
        verGUID: ver.VerGUID,
        hasCard: hasCard,
      });
    } catch (e) {
      setLoading(false);
      setErrorHandler('error');
      setError(true);
    }
    setLoading(false);
  };
  const submitHasPin = async (hasPin) => {
    let result = {};
    try {
      setLoading(true);
      result = await axios.post(baseURL + '/haspin', {
        verGUID: ver.VerGUID,
        hasPin: hasPin,
      });
    } catch (e) {
      setLoading(false);
      setErrorHandler('error');
      setError(true);
      return;
    }
    setLoading(false);
    setStep(3);
  };

  const Intro = (props) => {
    const p =
      strings.dir.toLowerCase() === 'ltr' ? (
        <P dir='LTR'>{strings.intro}</P>
      ) : (
        <P dir='RTL'>{strings.intro}</P>
      );

    return (
      <>
        {p}
        <SubmitButton onClick={handleSubmitIntro} style={{ marginTop: '10px' }}>
          {strings.ok}
        </SubmitButton>
      </>
    );
  };
  const BackBtn = (props) => {
    return (
      <Back
        onClick={(e) => {
          setStep(step > 0 ? step - 1 : 0);
        }}
      >
        {strings.goBack}
      </Back>
    );
  };

  const otpDiv = (
    <DIV>
      <P>{strings.enterOTP}</P>

      <Input
        maxLength={config.otp}
        type='number'
        inputMode='numeric'
        pattern='[0-9]{6}'
        ref={otpRef}
        onKeyDown={handleKeyDown}
        autoFocus
      />

      <SubmitButton onClick={handleSubmitOTP} style={{ marginTop: '10px' }}>
        {strings.submit}
      </SubmitButton>
      {!!showSendAnother && (
        <SendAnotherOTPButton
          onClick={handleSubmitPhone}
          style={{ marginTop: '10px' }}
        >
          {strings.submitAnotherOTP}
        </SendAnotherOTPButton>
      )}
    </DIV>
  );
  const pgnDiv = (
    <DIV>
      <P>{strings.enterPGN}</P>
      <InputMask mask={'*** - 99 C/- 9999999'} maskChar={'_'}>
        {(inputProps) => (
          <Input
            {...inputProps}
            ref={pgnRef}
            onKeyDown={handleKeyDown}
            autoFocus
          />
        )}
      </InputMask>
      <SubmitButton onClick={handleSubmitPGN} style={{ marginTop: '10px' }}>
        {strings.begin}
      </SubmitButton>
    </DIV>
  );
  const phoneDiv = (
    <DIV>
      <P>{strings.enterPhone}</P>
      <Input
        type='number'
        inputMode='tel'
        pattern='[0-9]*'
        ref={phoneRef}
        onKeyDown={handleKeyDown}
        autoFocus
      />
      <SubmitButton onClick={handleSubmitPhone} style={{ marginTop: '10px' }}>
        {strings.submit}
      </SubmitButton>
    </DIV>
  );
  const stillHasPhoneButtons = (
    <Fragment>
      <P>{strings.samePhone}</P>
      <Masked dir='LTR'>{maskedPhone}</Masked>
      <Center>
        <YesNoButton
          style={
            strings.dir.toLowerCase() == 'ltr'
              ? { marginRight: '0.2em' }
              : { marginLeft: '0.2em' }
          }
          onClick={() => {
            handleSubmitSamePhone(1);
            setStep(5);
          }}
        >
          {strings.yes}
        </YesNoButton>
        <YesNoButton
          onClick={() => {
            handleSubmitSamePhone(0);
            setStep(4);
          }}
        >
          {strings.no}
        </YesNoButton>
      </Center>
    </Fragment>
  );
  const hasCardButtons = (
    <Fragment>
      <P>{strings.stillHaveCard}</P>
      <Center>
        <YesNoButton
          style={
            strings.dir.toLowerCase() == 'ltr'
              ? { marginRight: '0.2em' }
              : { marginLeft: '0.2em' }
          }
          onClick={() => {
            setHasCard(true, () => {
              submitHasCard(1);
            });
          }}
        >
          {strings.yes}
        </YesNoButton>
        <YesNoButton
          onClick={() => {
            setHasCard(false, () => {
              submitHasCard(0);
              setStep(3);
            });
          }}
        >
          {strings.no}
        </YesNoButton>
      </Center>
    </Fragment>
  );
  const hasPinButtons = (
    <Fragment>
      <P>{strings.stillHavePin}</P>
      <Center>
        <YesNoButton
          style={
            strings.dir.toLowerCase() == 'ltr'
              ? { marginRight: '0.2em' }
              : { marginLeft: '0.2em' }
          }
          onClick={() => {
            setHasPin(1, (hasPin) => {
              submitHasPin(hasPin);
            });
          }}
        >
          {strings.yes}
        </YesNoButton>
        <YesNoButton
          onClick={() => {
            submitHasPin(0);
          }}
        >
          {strings.no}
        </YesNoButton>
      </Center>
    </Fragment>
  );
  const digitsDiv = (
    <DIV>
      <P
        dangerouslySetInnerHTML={{
          __html: `${strings.enterDigits}<br><span style="font-size: 12pt">XXXX-XXXX-XXXX-<b style="color: ${c.danger}">XXXX</b></span>`,
        }}
      ></P>
      <Input
        maxLength={config.digits}
        minLength={config.digits}
        type='number'
        inputMode='numeric'
        ref={digitsRef}
        onKeyDown={handleKeyDown}
        autoFocus
      />
      <SubmitButton onClick={handleSubmitDigits} style={{ marginTop: '10px' }}>
        {strings.submit}
      </SubmitButton>
      {remaining}
    </DIV>
  );
  const hasCardDiv = (
    <HasCardPin>
      {!hasCard && hasCardButtons}
      {!!hasCard && digitsDiv}
    </HasCardPin>
  );
  const hasPinDiv = <HasCardPin>{!hasPin && hasPinButtons}</HasCardPin>;
  const stillHasPhoneDiv = <HasCardPin>{stillHasPhoneButtons}</HasCardPin>;

  let Content = null;
  switch (step) {
    case -1:
      Content = <Intro />;
      break;
    case 0:
      Content = pgnDiv;
      // setIcon('folder');
      break;
    case 1:
      Content = hasCardDiv;
      // setIcon('card');
      break;
    case 2:
      Content = hasPinDiv;
      // setIcon('pin');
      break;
    case 3:
      Content = stillHasPhoneDiv;
      break;
    case 4:
      Content = phoneDiv;
      break;
    case 5:
      Content = otpDiv;
      break;
    default:
      Content = pgnDiv;
  }
  return (
    <Fragment>
      {!!isMounted && (
        <Root dir={strings.dir}>
          <Logo src={logo} />
          {!!showIcon && <MyIcon icon={icon} />}
          {!!validOTP && (
            <Success
              text={hasCard ? strings.verComp : strings.noCard}
              // UNHCRWillContact={!(hasPin && hasCard)}
            />
          )}
          {!validOTP && (
            <>
              {!!loading && <Loading />}
              {!loading && !stop && !error && Content}
              {!loading && !!errorHandler && !error && (
                <ErrorHandler
                  lang={strings.lang}
                  setErrorHandler={setErrorHandler}
                  setStop={setStop}
                  msg={errorHandler}
                />
              )}
              {!!error && (
                <>
                  <ErrorHandler
                    lang={strings.lang}
                    setErrorHandler={setErrorHandler}
                    setStop={setStop}
                    msg={errorHandler}
                  />
                  <SubmitButton
                    onClick={() => {
                      window.location.reload();
                    }}
                  >
                    {strings.tryAgain}
                  </SubmitButton>
                </>
              )}
              {!loading && hasBack && <BackBtn />}
            </>
          )}
        </Root>
      )}
      <GlobalStyle />
    </Fragment>
  );
}

export default App;
