import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Root, P, Header } from './styles';
import c from '../../utils/constants';

const Error = (props) => {
  return (
    <Root>
      <Header>
        <FontAwesomeIcon size='5x' color={c.danger} icon={faTimes} />
      </Header>
      <P>{props.ErrMsgDesc || `Error`}</P>
    </Root>
  );
};

export default Error;
