import styled from 'styled-components';
import c from '../../utils/constants';

export const Root = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0.5em;
`;
export const P = styled.p`
  color: ${c.primary};
  font-size: 2em;
  text-align: center;
`;
export const Header = styled(Root)`
  font-size: 4em;
`;
